<template>
	<div class="home">
		<h1 class="home--title">Welcome to <span class="home--title-phrase">League Metrics</span></h1>

		<p class="home--para">
			<br><br>
			Just went live with a site redesign. It is not 100% finished at this point, but it is far enough along that it was worth switching to it now. I am still working on fixing the bugs related to site design, but feel free to drop into our Discord to let me know of anything you would like to see fixed/added/updated/etc.
			<br><br>
		</p>

		<p class="home--para">
			<br><br>
			Added numerous stats to the Champion pages table view. You can access the table view by clicking on <a href="https://leaguemetrics.lol/champion?view=table">"(view more)"</a> within a top 5 stat summary block. There are still some bugs to squash concerning the newly added stats (as well as for the entire site unfortunately) so please feel free to drop by our <a href="https://discord.gg/szcx3MJ">Discord server</a> (https://discord.gg/szcx3MJ) to let us know about any issues you encounter. As a note, a few of the new stats are currently blank as we work on figuring out the best way to accurately capture and report them. We will also be working on cleaning up the table header titles to be clearer on what stat they represent and have tooltips where needed to provide more details.
			<br><br>
			In the immediate future we will be working on a small to medium size redesign of the site, tidying up & finishing the Champion page's table view stats, & progressing on the various Champion page layouts (particularly the matchup layout for when you select both a Champion & Lane Opponent).
			<br><br>
			In the slightly longer term plan we will begin working on adding a Summoner page to the site. This page will (most likely) use all of the same stats shown on the Champion pages, but you will also be able to compare your individual champ/role/overall/etc. stats to see how you stack up to other players in different situations (e.g. how your Shen support stats compare to you on other supports, other Shen support players, other supports in general, etc.).
			<br><br>
		</p>
	</div>
</template>

<script>
export default {
	name: 'Home'
};
</script>

<style lang="scss" scoped>
.home {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem 4rem 0 4rem;

	&--title {
		font-size: 3rem;
		color: var(--white);
	}

	&--para {
		margin: 1rem 0;
		max-width: 900px;
		text-align: left;
		color: var(--white);
		font-size: 14px;
	}
}

.date-title {
	color: var(--primary-gold);
	font-size: 16px;
}
</style>
